export const ONBOARDING_REDIRECT_KEY = 'redirectAfterOnboarding'

/** The session storage key to skip the feedback screen after on-boarding */
export const SKIP_ONBOARDING_NPS = 'skipOnboardingNPS'

export const ONBOARDING_STEPS = 'step'

export const LANDLORD_ONBOARDING = 'landlordOnboarding'

export const PERSONAL_DETAILS_STEPS = [
  'EMAIL',
  'FIRST_NAME',
  'PHONE_NUMBER',
  'DATE_OF_BIRTH',
  'NATIONALITY',
  'PASSPORT_NUMBER',
  'OCCUPATION',
  'IS_BUY_TO_LET',
  'COUNTRY',
  'REVIEW',
]

export const SKIP_ONBOARDING_LANDLORD = ['IS_BUY_TO_LET', 'PASSPORT_NUMBER']

export const SKIP_ONBOARDING_WEB_SESSION = ['EMAIL']
