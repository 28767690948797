import { useState, useEffect } from 'react'
import useSessionStorage from 'src/hooks/useSessionStorage'
import {
  getJwtToken,
  getWebSessionToken,
  decodeAuthStorage,
  setAuthCookie,
  Auth,
} from '../utils/auth'
import { createWebSessionUser as apiCreateWebSessionUser } from 'src/api/ggAPI'
import { ONBOARDING_STEPS } from 'src/components-pages/onboarding/consts'

export default function useWebUserAuth() {
  const [webUser, setWebUser] = useState<Auth | null>(null)
  const [isWebSessionUser, setIsWebSessionUser] = useState(false)
  const { saveToSessionStorage } = useSessionStorage()

  const setWebUserAndToken = (token: string) => {
    setAuthCookie(token)
    setWebUser(decodeAuthStorage(token))
    setIsWebSessionUser(true)
  }

  const createWebSessionUser = () => {
    return apiCreateWebSessionUser()
      .then((res) => {
        const { token } = res
        setWebUserAndToken(token)
        return res
      })
      .catch((err) => {
        console.error('Failed to create web session user:', err)
        return null
      })
  }

  useEffect(() => {
    const jwtToken = getJwtToken()
    const webSessionToken = getWebSessionToken()

    // If a regular JWT token exists, the user is not a web session user
    if (jwtToken) {
      setIsWebSessionUser(false)
      return
    }

    // If a web session token exists, set the user as a web session user
    if (webSessionToken) {
      setWebUserAndToken(webSessionToken)
      return
    }

    if (!webSessionToken && !jwtToken) {
      saveToSessionStorage(ONBOARDING_STEPS, '0')
      return
    }
  }, [])

  return {
    webUser,
    isWebSessionUser,
    createWebSessionUser,
  }
}
