import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Redirect from 'src/components/Redirect'
import useSessionStorage from 'src/hooks/useSessionStorage'
import { isAuthenticatedUser, isWebSessionUser, getWebSessionToken } from 'src/utils/auth'
import { loginUrl } from 'src/utils/ggUrl'
import useWebUserAuth from 'src/hooks/useWebUserAuth'
import { useTypedDispatch, useTypedSelector } from 'src/state/reducers'
import { getUser } from 'src/state/slices/user.slice'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useGetMigrationStatus } from 'src/api/wallet/queries'
import { useGetCompanies } from 'src/api/company/queries'

const ProtectedRoutes = ({ webSessionAccessible }: { webSessionAccessible?: boolean }) => {
  const { pfsAccountsMigration } = useFlags()
  const dispatch = useTypedDispatch()
  const user = useTypedSelector((state) => state.user)
  const { saveToSessionStorage } = useSessionStorage()
  const { createWebSessionUser } = useWebUserAuth()
  const [webSessionCreated, setWebSessionCreated] = useState(false)
  const [sessionUser, setSessionUser] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()

  const redirectToLogin = !webSessionAccessible && !isAuthenticatedUser()

  const { data: companiesData, isLoading: isCompaniesLoading } = useGetCompanies({
    enabled: !redirectToLogin,
  })
  const { data: migrations, isLoading: isMigrationLoading } = useGetMigrationStatus({
    enabled: !redirectToLogin,
  })

  const saveRedirectUrlToSessionStorage = () => {
    const pathname = window.location?.pathname?.replace('/', '')
    if (pathname?.length) {
      saveToSessionStorage('redirectTo', `${pathname}${window.location.search}`)
    }
  }

  useEffect(() => {
    const initSession = () => {
      if (
        webSessionAccessible &&
        !isAuthenticatedUser() &&
        !webSessionCreated &&
        !isWebSessionUser()
      ) {
        createWebSessionUser()
          .then(() => {
            setWebSessionCreated(true)
            setSessionUser(true)
            const token = getWebSessionToken()
            if (token) {
              dispatch(getUser())
            } else {
              console.error('Web session token is null after creation')
              navigate('/onboarding/create-account')
            }
          })
          .catch((error) => {
            console.error('Error initializing web session:', error)
            navigate('/onboarding/create-account')
          })
      } else {
        setSessionUser(isWebSessionUser())
      }
    }
    initSession()
  }, [webSessionAccessible, webSessionCreated, createWebSessionUser, dispatch, navigate])

  useEffect(() => {
    if (!user.id && (isAuthenticatedUser() || (webSessionAccessible && isWebSessionUser()))) {
      dispatch(getUser())
    }
  }, [dispatch, user.id, webSessionAccessible])

  // check for PFS wallet migration status
  // all wallet that user is director and status AWAITING_TERMS_CONDITIONS
  const directorWalletIds = (companiesData?.companies || [])
    .filter((c) => c.is_user_director)
    .map((c) => c.wallet_id)
  const walletMigrations = (migrations?.wallet_migrations || []).filter((m) =>
    directorWalletIds.includes(m.from_wallet_id),
  )
  const isMigrationPending =
    pfsAccountsMigration &&
    walletMigrations?.length > 0 &&
    walletMigrations.every((m) => m.status === 'AWAITING_TERMS_CONDITIONS')

  useEffect(() => {
    if (isMigrationLoading || isCompaniesLoading) return
    if (isMigrationPending) {
      navigate('/switch-account')
      return
    }
  }, [isMigrationPending, isMigrationLoading, isCompaniesLoading, navigate])

  if (redirectToLogin) {
    saveRedirectUrlToSessionStorage()
    return <Redirect to={loginUrl()} />
  }

  return <Outlet context={{ isWebSessionUser: sessionUser }} />
}

export default ProtectedRoutes
