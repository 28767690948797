import { useQuery } from '@tanstack/react-query'
import {
  getCompanies,
  getCompany,
  getBilling,
  getDocuments,
  getDocumentsCategories,
  getExpensesCategories,
  getCompanyProducts,
  getCompanyProduct,
  getGGPayments,
  getOnboardingStockCompanies,
  getCompanyShareholders,
  retrievePPForShareholder,
} from './routes'

export const companyKeys = {
  companies: ['companies'] as const,
  company: (id: number) => ['company', id] as const,
  company_billing: (companyID: number) => [...companyKeys.company(companyID), 'billing'] as const,
  company_documents: (companyID: number) =>
    [...companyKeys.company(companyID), 'documents'] as const,
  company_payments: (companyID: number) => [...companyKeys.company(companyID), 'payments'] as const,
  company_product: (companyProductID: number) =>
    [...companyKeys.company(companyProductID), 'products'] as const,
  company_shareholders: (companyID: number) =>
    [...companyKeys.company(companyID), 'shareholders'] as const,
  company_documents_categories: ['company', 'documents', 'categories'] as const,
  company_expenses_categories: ['company', 'documents', 'expenses', 'categories'] as const,
  company_products: ['companies', 'products'] as const,
  company_onboarding_stock: ['companies', 'onboardings'] as const,
}

export const useGetCompany = ({ id }: { id: number }) =>
  useQuery({
    queryKey: companyKeys.company(id),
    queryFn: () => getCompany(id),
    enabled: Boolean(id),
    staleTime: 10 * 1000,
  })

export const useGetCompanies = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: companyKeys.companies,
    queryFn: () => getCompanies(),
    staleTime: 10 * 1000,
    enabled,
  })

export const useGetCompanyBilling = ({ companyID }: { companyID: number }) =>
  useQuery({
    queryKey: companyKeys.company_billing(companyID),
    queryFn: () => getBilling(companyID),
    enabled: Boolean(companyID),
  })

export const useGetCompanyDocuments = ({ id }: { id: number }) =>
  useQuery({
    queryKey: companyKeys.company_documents(id),
    queryFn: () => getDocuments(id),
    enabled: Boolean(id),
  })

export const useGetGGCompanyPayments = ({ companyID }: { companyID: number }) =>
  useQuery({
    queryKey: companyKeys.company_payments(companyID),
    queryFn: () => getGGPayments(companyID),
    enabled: Boolean(companyID),
  })

export const useGetCompanyDocumentsCategories = () =>
  useQuery({
    queryKey: companyKeys.company_documents_categories,
    queryFn: () => getDocumentsCategories(),
    staleTime: 5 * 60 * 1000,
  })

export const useGetExpensesCategories = () =>
  useQuery({
    queryKey: companyKeys.company_expenses_categories,
    queryFn: () => getExpensesCategories(),
    staleTime: 5 * 60 * 1000,
  })

export const useGetCompanyProducts = () =>
  useQuery({
    queryKey: companyKeys.company_products,
    queryFn: () => getCompanyProducts(),
    staleTime: 10 * 1000,
  })

export const useGetOnboardingStockCompanies = () =>
  useQuery({
    queryKey: companyKeys.company_onboarding_stock,
    queryFn: () => getOnboardingStockCompanies(),
    staleTime: 10 * 1000,
  })

export const useGetCompanyProduct = ({ companyProductID }: { companyProductID: number }) =>
  useQuery({
    queryKey: companyKeys.company_product(companyProductID),
    queryFn: () => getCompanyProduct({ companyProductID }),
    enabled: !!companyProductID,
  })

export const useGetCompanyShareholders = ({ companyID }: { companyID: number }) =>
  useQuery({
    queryKey: companyKeys.company_shareholders(companyID),
    queryFn: () => getCompanyShareholders(companyID),
    enabled: !!companyID,
  })

export const useGetShareholderDetails = ({ token }: { token?: string }) =>
  useQuery({
    queryKey: ['shareholders', token],
    queryFn: () => retrievePPForShareholder(token),
    enabled: !!token,
  })
