import axios from 'axios'
import {
  PotDepositInterval,
  type InvestmentPot,
  type InvestmentPotBalance,
  type InvestmentPotTransaction,
  type RegularPotDeposit,
} from 'src/types/InvestmentPot'
import { Shareholder } from 'src/types/CompanyShareholder'
import { InvestmentPotApplicant } from 'src/types/InvestmentPotApplicant'

interface InvestmentPotRequestBase {
  companyID: number
  walletID: number
  potID: InvestmentPot['id']
  amount: number
  after?: string
  limit?: number
  withdrawFullAmount: boolean
  payDay: number
  depositID: RegularPotDeposit['id']
}
export type InvestmentPotRequest = Pick<InvestmentPotRequestBase, 'walletID' | 'potID'>

/* CREATE POT */
export type CreatePotRequest = {
  wallet_id: InvestmentPotRequestBase['walletID']
  company_id?: InvestmentPotRequestBase['companyID']
  amended_applicant_details?: InvestmentPotApplicant[]
}

export const createPot = (data: CreatePotRequest) =>
  axios.post<InvestmentPot>(`v3/investment_accounts`, data)

/* GET POT INFO */
export type GetInvestmentPotRequest = Pick<InvestmentPotRequestBase, 'walletID'>
type GetInvestmentPotResponse = { accounts: InvestmentPot[] }

export const getInvestmentPot = ({ walletID }: GetInvestmentPotRequest) =>
  axios
    .get<GetInvestmentPotResponse>(`v3/wallets/${walletID}/investment_accounts`)
    .then((response) => response.accounts?.[0] ?? null)

/* GET POT BALANCE */
export const getInvestmentPotBalance = ({ walletID, potID }: InvestmentPotRequest) =>
  axios.get<InvestmentPotBalance>(`v3/wallets/${walletID}/investment_accounts/${potID}/balance`)

/* ADD MONEY */
export type AddMoneyRequest = Pick<InvestmentPotRequestBase, 'walletID' | 'potID' | 'amount'>

export const addMoney = ({ walletID, potID, amount }: AddMoneyRequest) =>
  axios.post(`v3/wallets/${walletID}/investment_accounts/${potID}/add_funds`, {
    amount_in_minor_units: amount,
  })

/* WITHDRAW BALANCE */
export type WithdrawMoneyRequest = Pick<
  InvestmentPotRequestBase,
  'walletID' | 'potID' | 'amount' | 'withdrawFullAmount'
>
export type WithdrawMoneyResponse = { success?: boolean }

export const withdrawMoney = ({
  walletID,
  potID,
  amount,
  withdrawFullAmount,
}: WithdrawMoneyRequest) =>
  axios.post<WithdrawMoneyResponse>(
    `v3/wallets/${walletID}/investment_accounts/${potID}/withdraw_funds`,
    { amount_in_minor_units: amount, full_amount: withdrawFullAmount },
  )

/* GET SETTLED TRANSACTIONS */
export type GetPotTransactionsRequest = Pick<
  InvestmentPotRequestBase,
  'walletID' | 'potID' | 'after' | 'limit'
>

export type GetPotTransactionsResponse = {
  transactions: InvestmentPotTransaction[]
  after?: InvestmentPotRequestBase['after'] // to be passed as "after" param for subsequent requests
}

export const getInvestmentPotTransactions = ({
  walletID,
  potID,
  after = '',
  limit = 10,
}: GetPotTransactionsRequest) =>
  axios.get<GetPotTransactionsResponse>(
    `v3/wallets/${walletID}/investment_accounts/${potID}/transactions?limit=${limit}&after=${after}`,
  )

/* GET MATCHED TRANSACTIONS */
export type GetPotPendingTransactionsResponse = {
  transactions: InvestmentPotTransaction[]
}

export const getInvestmentPotPendingTransactions = ({ walletID, potID }: InvestmentPotRequest) =>
  axios.get<GetPotPendingTransactionsResponse>(
    `v3/wallets/${walletID}/investment_accounts/${potID}/pending_transactions`,
  )

/* GET REGULAR DEPOSIT */
export type GetPotRegularDepositsResponse = {
  regular_deposits: RegularPotDeposit[]
}

export const getRegularDeposits = ({ walletID, potID }: InvestmentPotRequest) =>
  axios.get<GetPotRegularDepositsResponse>(
    `v3/wallets/${walletID}/investment_accounts/${potID}/regular_deposits`,
  )

/* UPDATE REGULAR DEPOSIT */
export type MakeRegularDeposit = Pick<
  InvestmentPotRequestBase,
  'companyID' | 'potID' | 'amount' | 'payDay'
>

export const makeRegularDeposit = ({ companyID, potID, amount, payDay }: MakeRegularDeposit) =>
  axios.post(`v3/companies/${companyID}/investments_accounts/${potID}/regular_deposits`, {
    consideration: {
      amount_in_minor_units: amount,
    },
    recurrence: {
      // default monthly for now
      interval: PotDepositInterval.MONTHLY,
      day_of_interval: payDay,
    },
  })

/* UPDATE REGULAR DEPOSIT */
export type UpdateRegularDeposit = Pick<
  InvestmentPotRequestBase,
  'companyID' | 'potID' | 'amount' | 'payDay' | 'depositID'
>

export const updateRegularDeposit = ({
  companyID,
  potID,
  amount,
  payDay,
  depositID,
}: UpdateRegularDeposit) =>
  axios.put(
    `v3/companies/${companyID}/investments_accounts/${potID}/regular_deposits/${depositID}`,
    {
      consideration: {
        amount_in_minor_units: amount,
      },
      recurrence: {
        interval: PotDepositInterval.MONTHLY,
        day_of_interval: payDay,
      },
    },
  )

/* DELETE REGULAR DEPOSIT */
export type CancelRegularDeposit = Pick<
  InvestmentPotRequestBase,
  'companyID' | 'potID' | 'depositID'
>

export const cancelRegularDeposit = ({ companyID, potID, depositID }: CancelRegularDeposit) =>
  axios.delete(
    `v3/companies/${companyID}/investments_accounts/${potID}/regular_deposits/${depositID}`,
  )

/* GET Shareholder kyc details */
type ShareholdersResponse = { shareholders: Shareholder[] }

export const getCompanyShareholders = ({
  companyID,
}: Pick<InvestmentPotRequestBase, 'companyID'>) =>
  axios
    .get<ShareholdersResponse>(`v3/companies/${companyID}/shareholders_kyc_details`)
    .then((response) => response.shareholders || [])

export type GetPotPerformanceResponse = {
  total_deposits: {
    amount_in_minor_units: number
  }
  total_withdrawals: {
    amount_in_minor_units: number
  }
  total_growth: {
    amount_in_minor_units: number
  }
}

export const getPotPerformance = ({ walletID, potID }: InvestmentPotRequest) =>
  axios.get<GetPotPerformanceResponse>(
    `v3/wallets/${walletID}/investment_accounts/${potID}/performance`,
  )

export type GetPotYieldResponse = {
  gross_yield_in_bps: number
  net_yield_in_bps: number
}
export const getPotYield = () => axios.get<GetPotYieldResponse>(`v3/investment_accounts/yield`)
