import axios, { AxiosResponse } from 'axios'
import Payee from 'src/types/Payee'
import WalletInfo, { ForeignBalance } from 'src/types/WalletInfo'
import CompanyAccount from 'src/types/CompanyAccount'
import CurrencyConversion from 'src/types/CurrencyConversion'
import { Wallet } from 'src/types/wallet'
import Cashflow from 'src/types/Cashflow'
import StandingOrderPayload from 'src/types/StandingOrderPayload'
import type { TransactionDetails } from 'src/components-pages/Portfolio/Wallet/SendMoney/types'
import type { AccountMigration } from 'src/types/AccountMigration'

export interface TransferFundPayload {
  otp: string
  reference: string
  amount_in_cents: number
  payee: Payee & {
    persist: boolean
  }
  transaction_details: TransactionDetails
}

export const getWallets = async () => axios.get<{ wallets: Wallet[] }>(`v3/wallets`)

export const getWalletInfo = (walletID: number): Promise<WalletInfo> =>
  axios.get(`v3/wallets/${walletID}`)

interface GetExchangeCurrenciesResponse {
  exchange_currencies: ForeignBalance[]
}

export const getExchangeCurrencies = (walletID: number) =>
  axios
    .get<GetExchangeCurrenciesResponse>(`v3/wallets/${walletID}/exchange_currencies`)
    .then((res) => res?.exchange_currencies || [])

export const getWalletCashflow = (walletID: number): Promise<{ cashflows: Cashflow }> =>
  axios.get(`v3/wallets/${walletID}/cashflows`)

export const openInternationalAccount = (companyID: number): Promise<AxiosResponse> =>
  axios.post(`v3/companies/${companyID}/add_currency_cloud_account`)

export const getPendingConversions = (companyID: number): Promise<CurrencyConversion> =>
  axios.get(`v3/companies/${companyID}/account/pending_conversions`)

export const transferFunds = (
  walletID: number,
  data: TransferFundPayload,
): Promise<AxiosResponse> => axios.post(`v3/wallets/${walletID}/transfer_funds`, data)

export const getCompanyAccounts = (): Promise<{ accounts: CompanyAccount[] }> =>
  axios.get('v3/users/me/companies/accounts')

export const createInternationalAccount = (companyID: number) =>
  axios.post(`v3/companies/${companyID}/add_currency_cloud_account`)

export const getUserAnalytics = () => axios.get(`v3/analytics/investments`)

interface CreateStandingOrderResponse extends Omit<StandingOrderPayload, 'otp'> {
  id: number
  payments_made: number
  next_payment_date: string
  is_editable: boolean
}

export const createStandingOrder = (
  walletID: number,
  data: StandingOrderPayload,
): Promise<CreateStandingOrderResponse> =>
  axios.post(`v3/wallets/${walletID}/standing_orders`, data)

export const walletStatement = (walletID: number, query: string) =>
  axios.get(`v3/wallets/${walletID}/account-statement${query}`)

export const getWalletStatements = (
  walletID: number,
  params: {
    start_date: string
    end_date: string
    format: 'pdf' | 'csv'
  },
) => axios.get(`v3/wallets/${walletID}/statements`, { params, responseType: 'blob' })

export interface GetExchangeRateResponse {
  buy: string
  currency_pair: string
  get_ground_takeout_rate: number
  sell: string
}

export const getExchangeRate = (currencyPair: string) =>
  axios.get<GetExchangeRateResponse>('/v2/wallet/exchange_rate', {
    params: { currency_pair: currencyPair },
  })

export const sendExchangeCurrencyRequest = (
  companyID: number,
  amount: string,
  currencyPair: string,
) =>
  axios.post(`v2/companies/${companyID}/e-wallet/exchange_currency`, {
    amount_in_major_units: amount,
    currency_pair: currencyPair,
  })

interface CategorySuggestion {
  reference_category: Record<
    string,
    {
      category: string
      subcategory: string
      category_additional_info: string
    }
  >
}

export const getCategorySuggestions = (walletId?: number) => {
  if (!walletId) {
    return
  }

  return axios.get<CategorySuggestion>(`v3/wallets/${walletId}/transactions_categories`)
}
export interface MigrationStatusResponse {
  wallet_migrations: AccountMigration[]
}

export const getWalletMigrationStatus = () =>
  axios.get<MigrationStatusResponse>(`v3/wallet_migrations`)

export const updateWalletMigrationConsent = ({ userID, type }: { userID: number; type: string }) =>
  axios.post(`v3/users/${userID}/accept_terms`, { consent_type: type })

export const createWalletMigration = (walletID: number) =>
  axios.post(`v3/wallets/${walletID}/initialize-wallet-migration`)
